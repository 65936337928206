(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/get-balance/assets/javascripts/get-balance.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/get-balance/assets/javascripts/get-balance.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  cacheTime
} = svs.components.marketplaceShared.getBalance.constants;
const {
  normalizeActivatedDraws
} = svs.components.marketplaceShared.getBalance;
const {
  groupTypes
} = svs.components.marketplaceData.constants;
const {
  DRAWSTATE_FINALIZED,
  DRAWSTATE_PENDING,
  DRAWSTATE_ERROR,
  DRAWSTATE_EMPTY
} = svs.components.marketplaceData.constants;
const {
  jupiterFetch
} = svs.core;
const balances = new Map();
const {
  products
} = svs.utils;
const getFetchUrl = (role, activatedDraw, product, drawNumber) => {
  let url;
  if (role === groupTypes.PRIVATE && product && drawNumber) {
    const qs = new URLSearchParams();
    qs.append('product', product);
    qs.append('drawNumber', drawNumber);
    url = "marketplace/1/activateddraw/balance?".concat(qs);
  }
  if (role === groupTypes.RETAILER) {
    url = "retailer/1/marketplace/activateddraws/".concat(activatedDraw);
  }
  return url;
};
const getAccountBalance = {};
const updateBalance = (activatedDrawId, balance, trigger) => {
  balances.set(activatedDrawId, balance);
  trigger && getAccountBalance.trigger('change', balance);
  return Object.freeze(balance);
};
const listenerForPendingPromise = activatedDrawId => new Promise(resolve => {
  const listener = activatedDraw => {
    if (activatedDrawId === activatedDraw.data.activatedDrawId) {
      resolve(activatedDraw);
      getAccountBalance.off('change', listener);
    }
  };
  getAccountBalance.on('change', listener);
});
getAccountBalance.getBalance = async function (role, productId, drawNumber, activatedDrawId) {
  var _balances$get, _currentBalance$state, _data$activatedDraws;
  let force = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
  const currentBalance = balances.get(activatedDrawId);
  const hasBalance = currentBalance && ![DRAWSTATE_EMPTY, DRAWSTATE_ERROR].includes(currentBalance.state.status);
  const isOutdatedBalance = hasBalance && ((_balances$get = balances.get(activatedDrawId)) === null || _balances$get === void 0 || (_balances$get = _balances$get.state) === null || _balances$get === void 0 ? void 0 : _balances$get.timeStamp) < Date.now() - cacheTime;
  if (![groupTypes.PRIVATE, groupTypes.RETAILER].includes(role)) {
    return;
  }
  if ((currentBalance === null || currentBalance === void 0 || (_currentBalance$state = currentBalance.state) === null || _currentBalance$state === void 0 ? void 0 : _currentBalance$state.status) === DRAWSTATE_PENDING) {
    return listenerForPendingPromise(activatedDrawId);
  }
  if (!force && hasBalance && !isOutdatedBalance) {
    return balances.get(activatedDrawId);
  }
  if (!hasBalance) {
    balances.set(activatedDrawId, {
      state: {
        status: DRAWSTATE_PENDING
      }
    });
  }
  const product = products.getSpecificDrawName(productId);
  const fetchUrl = getFetchUrl(role, activatedDrawId, product, drawNumber);
  const response = await jupiterFetch.fetch("".concat(fetchUrl), {
    method: 'GET'
  });
  const data = await response.json();
  if (!response.ok) {
    if (hasBalance) {
      return balances.get(activatedDrawId);
    }
    const newBalance = {
      state: {
        status: DRAWSTATE_ERROR,
        timeStamp: Date.now()
      }
    };
    return updateBalance(activatedDrawId, newBalance);
  }
  if (role === groupTypes.PRIVATE && data !== null && data !== void 0 && (_data$activatedDraws = data.activatedDraws) !== null && _data$activatedDraws !== void 0 && _data$activatedDraws.length) {
    var _currentBalance$data, _balance$data;
    const activatedDrawIsIncluded = data.activatedDraws.find(draw => draw.ActivatedDrawId === activatedDrawId);
    const currentAccountBalance = currentBalance === null || currentBalance === void 0 || (_currentBalance$data = currentBalance.data) === null || _currentBalance$data === void 0 ? void 0 : _currentBalance$data.balance;
    if (activatedDrawId && !activatedDrawIsIncluded) {
      balances.delete(activatedDrawId);
    }
    data.activatedDraws.forEach(draw => {
      updateBalance(draw.ActivatedDrawId, normalizeActivatedDraws(draw, role));
    });
    const balance = balances.get(activatedDrawId);
    const newAccountBalance = balance === null || balance === void 0 || (_balance$data = balance.data) === null || _balance$data === void 0 ? void 0 : _balance$data.balance;
    return updateBalance(activatedDrawId, balances.get(activatedDrawId), currentAccountBalance !== newAccountBalance);
  }
  if (role === groupTypes.RETAILER && Object.keys(data === null || data === void 0 ? void 0 : data.activatedDraw).length) {
    var _currentBalance$data2;
    const newBalance = normalizeActivatedDraws(data.activatedDraw, role);
    const currentAccountBalance = currentBalance === null || currentBalance === void 0 || (_currentBalance$data2 = currentBalance.data) === null || _currentBalance$data2 === void 0 ? void 0 : _currentBalance$data2.balance;
    const newAccountBalance = newBalance.data.balance;
    return updateBalance(data.activatedDraw.activatedDrawId, newBalance, currentAccountBalance !== newAccountBalance);
  }
  const emptyBalance = {
    state: {
      status: DRAWSTATE_EMPTY,
      timeStamp: Date.now()
    }
  };
  return updateBalance(activatedDrawId, emptyBalance);
};
getAccountBalance.getAllBalances = async (role, productId, drawNumber, activatedDrawId) => {
  await getAccountBalance.getBalance(role, productId, drawNumber, activatedDrawId, true);
  return balances;
};
getAccountBalance.getCachedBalance = activatedDrawId => balances.get(activatedDrawId) || null;
getAccountBalance.setBalance = (activatedDrawId, draw) => {
  balances.set(activatedDrawId, draw);
};
getAccountBalance.deleteBalance = activatedDrawId => {
  const currentBalance = balances.get(activatedDrawId) || {};
  balances.set(activatedDrawId, _objectSpread(_objectSpread({}, currentBalance), {}, {
    state: _objectSpread(_objectSpread({}, currentBalance.state), {}, {
      status: DRAWSTATE_FINALIZED
    })
  }));
  getAccountBalance.trigger('change', balances.get(activatedDrawId));
  balances.delete(activatedDrawId);
};
getAccountBalance.calculateNewShares = async (role, productId, drawNumber, activatedDrawId) => {
  var _newValue$data, _cachedBalance$data;
  let isChangedBalance = false;
  let numberOfNewShares;
  let balance;
  const cachedBalance = getAccountBalance.getCachedBalance(activatedDrawId);
  if (!cachedBalance) {
    return {
      isChangedBalance,
      numberOfNewShares,
      balance
    };
  }
  const newValue = await getAccountBalance.getBalance(role, productId, drawNumber, activatedDrawId, true);
  if (parseInt(newValue === null || newValue === void 0 || (_newValue$data = newValue.data) === null || _newValue$data === void 0 ? void 0 : _newValue$data.balance, 10) !== parseInt(cachedBalance === null || cachedBalance === void 0 || (_cachedBalance$data = cachedBalance.data) === null || _cachedBalance$data === void 0 ? void 0 : _cachedBalance$data.balance, 10)) {
    isChangedBalance = true;
    if (role === groupTypes.PRIVATE) {
      numberOfNewShares = parseInt(newValue.data.balance, 10) / parseInt(newValue.data.SharePrice, 10) - parseInt(cachedBalance.data.balance, 10) / parseInt(cachedBalance.data.SharePrice, 10);
    } else {
      numberOfNewShares = newValue.data.soldShares - cachedBalance.data.soldShares;
    }
    balance = parseInt(newValue.data.balance, 10);
  }
  return {
    isChangedBalance,
    numberOfNewShares,
    balance
  };
};
setGlobal('svs.components.marketplaceShared.getBalance.getAccountBalance', new svs.core.Events(getAccountBalance));

 })(window);
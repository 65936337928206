(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-shared/get-balance/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/marketplace-shared/get-balance/assets/javascripts/constants.js');
"use strict";


const cacheTime = 60 * 1000;
const objectToExport = {
  cacheTime
};
setGlobal('svs.components.marketplaceShared.getBalance.constants', objectToExport);

 })(window);